var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c(
        "v-card",
        {
          staticClass: "fill-height pt-5",
          attrs: { outlined: "", flat: "", width: "100%" }
        },
        [
          _c("app-form", {
            attrs: {
              fields: _vm.fields,
              "submit-button": _vm.submitButton,
              "disable-cancel-button": "",
              footerClass: "col-12 text-center my-5"
            },
            on: { submitted: _vm.handleSubmit }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }